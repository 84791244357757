const INITIAL_STATE_ENABLE = 'LOADING_STATE_ENABLE';
const INITIAL_STATE_DISABLE = 'LOADING_STATE_DISABLE';

const initialState = { loading: false };

export default function loading(state = initialState, action = {}) {
  switch (action.type) {
    case INITIAL_STATE_ENABLE:
      return { loading: true };
    case INITIAL_STATE_DISABLE:
      return { loading: false };
    default:
      return state;
  }
}

export function showLoading() {
  return {
    type: INITIAL_STATE_ENABLE,
  };
}

export function hideLoading() {
  return {
    type: INITIAL_STATE_DISABLE,
  };
}
