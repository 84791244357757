import React from 'react';
import './NeedAuth.scss';
import Button from '../Common/Button';
import Margin from '../Common/Margin';

const NeedAuth = (
  {
    need_auth,
    setNeedAuth,
    pushState,
  }
) => {
  const margin10 = <Margin margin={10}/>;
  const margin30 = <Margin margin={30}/>;
  return (
    <div
      style={need_auth.state ? {
        display: 'flex'
      } : {
        display: 'none'
      }}
      className={`need_auth ${need_auth.state ? 'need_auth_active' : ''}`}
    >
      <div>
        <h2>{need_auth.title}</h2>
        {margin30}
        <div>
          <Button
            style="brand"
            changed={
              () => {
                setNeedAuth(false);
                pushState('/ru/login');
              }
            }
          >
            Авторизоваться
          </Button>
          {margin10}
          <Button
            changed={() => setNeedAuth(false)}
          >
            Закрыть
          </Button>
        </div>
      </div>
    </div>
  );
};

export default NeedAuth;
