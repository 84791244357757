const INITIAL_SHOW_NOTICE_STATE_SHOW = 'SHOW_NOTICE_STATE_SHOW';
const INITIAL_SHOW_NOTICE_STATE_CLOSE = 'SHOW_NOTICE_STATE_CLOSE';

const initialState = {
  notify: [],
  currentId: 0,
};

export default function notify(state = initialState, action = {}) {
  switch (action.type) {
    case INITIAL_SHOW_NOTICE_STATE_SHOW:
      const currentId = state.currentId + 1;
      const newItem = {
        text: action.text,
        style: action.style,
        title: action.title,
        id: currentId
      };
      return {
        notify: [ ...state.notify, newItem ],
        currentId
      };
    case INITIAL_SHOW_NOTICE_STATE_CLOSE:
      const { id } = action;
      const result = state.notify.filter((item) => {
        return item.id !== id;
      });
      return {
        notify: result,
        currentId: state.currentId
      };

    default:
      return state;
  }
}

export function show_notify(text = '', style = 'success', title = '') {
  return {
    type: INITIAL_SHOW_NOTICE_STATE_SHOW,
    text,
    style,
    title,
  };
}

export function close_notify(id) {
  return {
    type: INITIAL_SHOW_NOTICE_STATE_CLOSE,
    id
  };
}
