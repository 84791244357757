import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import { reducer as reduxAsyncConnect } from 'redux-connect';
import { reducer as formReducer } from 'redux-form';
import { loadingBarReducer } from 'react-redux-loading-bar';
import auth from './modules/auth/auth';
import loading from './modules/loading/loading';
import notify from './modules/notify/notify';
import need_auth from './modules/auth/need_auth';

import specialties from './modules/specialty/specialties';
import specialty from './modules/specialty/specialty';

import universities from './modules/university/universities';
import university from './modules/university/university';
import university_block_admission_rules from './modules/university/blocks/admission_rules';
import university_block_contact_info from './modules/university/blocks/contact_info';
import university_block_enrollee_steps from './modules/university/blocks/enrollee_steps';
import university_block_faq from './modules/university/blocks/faq';
import university_block_history from './modules/university/blocks/history';
import university_block_hotline from './modules/university/blocks/hotline';
import university_block_main_call_center from './modules/university/blocks/main_call_center';
import university_block_mission from './modules/university/blocks/mission';
import university_block_strategic_objectives from './modules/university/blocks/strategic_objectives';
import university_block_training_courses from './modules/university/blocks/training_courses';
import university_block_vision from './modules/university/blocks/vision';
import university_block_worth from './modules/university/blocks/worth';

import profile_enrollee_dynamic_blocks from './modules/profile/enrollee/dynamic_blocks';
import profile_enrollee_entrance_steps from './modules/profile/enrollee/entrance_steps';
import profile_enrollee_entrance_step from './modules/profile/enrollee/entrance_step';
import profile_enrollee_anketa_is_allowed from './modules/profile/enrollee/anketa_is_allowed';
import profile_enrollee_entrance_is_allowed from './modules/profile/enrollee/entrance_is_allowed';
import profile_enrollee_entrance_list from './modules/profile/enrollee/entrance_list';
import profile_enrollee_entrance_show from './modules/profile/enrollee/entrance_show';
import profile_enrollee_balance_history from './modules/profile/enrollee/balance-history';

import subscribe_universities from './modules/subscribe/universities';
import subscribed_news from './modules/subscribe/news';

import profile_university_info from './modules/profile/university/info';
import profile_university_entrance_list from './modules/profile/university/entrance';
import profile_university_entrance_show from './modules/profile/university/entrance_show';
import profile_university_entrance_history from './modules/profile/university/entrance_history';
import profile_university_users from './modules/profile/university/users';
import profile_university_employers from './modules/profile/university/employers';
import profile_university_faculties from './modules/profile/university/faculties';
import profile_university_faculties_list from './modules/profile/university/faculties_list';
import profile_university_specialities from './modules/profile/university/specialities';
import profile_university_specialities_list from './modules/profile/university/specialities_list';
import profile_university_education_programs from './modules/profile/university/education_programs';
import profile_university_education_program from './modules/profile/university/education_program';
import profile_university_news from './modules/profile/university/news';
import profile_university_news_unit from './modules/profile/university/news_unit';
import profile_university_blocks from './modules/profile/university/blocks';
import profile_university_tests from './modules/profile/university/tests';

import news_show from './modules/news/show';

import test_start from './modules/test/start';
import test_finish from './modules/test/finish';

import ref_roles from './modules/refs/roles';

import ref_faculties from './modules/refs/faculties';
import ref_specialities from './modules/refs/specialities';
import ref_education_periods from './modules/refs/education_periods';
import ref_education_programs from './modules/refs/education_programs';
import ref_countries from './modules/refs/countries';
import ref_cities from './modules/refs/cities';
import ref_nations from './modules/refs/nations';
import ref_education_levels from './modules/refs/education_levels';
import ref_profile_dynamic_blocks from './modules/refs/profile_dynamic_blocks';
import ref_degrees from './modules/refs/degrees';
import ref_subjects from './modules/refs/subjects';
import ref_profile_types from './modules/refs/profile_types';
import ref_universities from './modules/refs/universities';
import ref_genders from './modules/refs/genders';
import ref_marital_statuses from './modules/refs/marital_statuses';
import ref_video_link_types from './modules/refs/video_link_types';
import ref_address_types from './modules/refs/address_types';
import ref_payment_types from './modules/refs/payment_types';
import ref_education_forms from './modules/refs/education_forms';
import ref_document_statuses from './modules/refs/document_statuses';
import ref_document_types from './modules/refs/document_types';
import ref_regions from './modules/refs/regions';
import ref_localities from './modules/refs/localities';
import ref_parent_types from './modules/refs/parent_types';
import ref_education_documents from './modules/refs/education_documents';
import ref_education_types from './modules/refs/education_types';
import ref_education_institution_types from './modules/refs/education_institution_types';
import ref_education_locales from './modules/refs/education_locales';
import ref_education_cert_types from './modules/refs/education_cert_types';
import ref_anketa_medical_types from './modules/refs/anketa_medical_types';
import ref_language_proficiency from './modules/refs/language_proficiency';
import ref_locales from './modules/refs/locales';
import ref_payer_types from './modules/refs/payer_types';
import ref_price_range from './modules/refs/price_range';
import ref_social_categories from './modules/refs/social_categories';
import ref_preemptive_rights from './modules/refs/preemptive_rights';
import ref_awards from './modules/refs/awards';

export default combineReducers({
  routing: routerReducer,
  reduxAsyncConnect,
  form: formReducer,
  loadingBar: loadingBarReducer,
  auth,
  need_auth,
  notify,
  loading,

  specialties,
  specialty,

  universities,
  university,
  university_block_admission_rules,
  university_block_contact_info,
  university_block_enrollee_steps,
  university_block_faq,
  university_block_history,
  university_block_hotline,
  university_block_main_call_center,
  university_block_mission,
  university_block_strategic_objectives,
  university_block_training_courses,
  university_block_vision,
  university_block_worth,

  profile_enrollee_dynamic_blocks,
  profile_enrollee_entrance_steps,
  profile_enrollee_entrance_step,
  profile_enrollee_entrance_is_allowed,
  profile_enrollee_anketa_is_allowed,
  profile_enrollee_entrance_list,
  profile_enrollee_entrance_show,
  profile_enrollee_balance_history,

  subscribe_universities,
  subscribed_news,

  profile_university_info,
  profile_university_entrance_list,
  profile_university_entrance_show,
  profile_university_entrance_history,
  profile_university_users,
  profile_university_employers,
  profile_university_faculties,
  profile_university_faculties_list,
  profile_university_specialities,
  profile_university_specialities_list,
  profile_university_education_programs,
  profile_university_education_program,
  profile_university_news,
  profile_university_news_unit,
  profile_university_blocks,
  profile_university_tests,

  news_show,

  test_finish,
  test_start,

  ref_roles,

  ref_faculties,
  ref_specialities,
  ref_education_periods,
  ref_education_programs,
  ref_countries,
  ref_cities,
  ref_nations,
  ref_education_levels,
  ref_profile_dynamic_blocks,
  ref_degrees,
  ref_subjects,
  ref_profile_types,
  ref_universities,
  ref_genders,
  ref_marital_statuses,
  ref_video_link_types,
  ref_address_types,
  ref_payment_types,
  ref_education_forms,
  ref_document_statuses,
  ref_document_types,
  ref_regions,
  ref_localities,
  ref_parent_types,
  ref_education_documents,
  ref_education_types,
  ref_education_institution_types,
  ref_education_locales,
  ref_education_cert_types,
  ref_anketa_medical_types,
  ref_language_proficiency,
  ref_locales,
  ref_payer_types,
  ref_price_range,
  ref_social_categories,
  ref_preemptive_rights,
  ref_awards,
});
