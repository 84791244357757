import config from '../../../../config';
import { getConst, getDefaultInitState } from '../../../helpers/functions';
import { UNIVERSITY_BLOCK_ADMISSION_RULES } from '../../../helpers/const';

const [ INITIAL_STATE_LOAD, INITIAL_STATE_SUCCESS, INITIAL_STATE_FAIL ] = getConst(UNIVERSITY_BLOCK_ADMISSION_RULES);

export default function university_block_admission_rules(state = getDefaultInitState(), action = {}) {
  switch (action.type) {
    case INITIAL_STATE_LOAD:
      return {
        ...state,
        loading: true
      };
    case INITIAL_STATE_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.result
      };
    case INITIAL_STATE_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error
      };

    default:
      return state;
  }
}

export function isLoaded(globalState) {
  return globalState.university_block_admission_rules && globalState.university_block_admission_rules.loaded;
}

export function load(id) {
  return {
    types: [ INITIAL_STATE_LOAD, INITIAL_STATE_SUCCESS, INITIAL_STATE_FAIL ],
    promise: (client) => client.get(`${config.apiHost}/universities/dynamic-blocks/admission-rules/all/${id}`)
  };
}
