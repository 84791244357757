import React from 'react';
import './Slider.scss';
import Margin from '../Common/Margin';

const Slider = () => {
  return (
    <div>
      <div className="main-page-top-block-slider">
        <h2>
          Поступить в учебное
          заведение быстро и легко
        </h2>
        <Margin margin={50}/>
        <p>
          Университет — многопрофильное учебное заведение с большим
          выбором учебных программ в самых разных областях знания.
        </p>
      </div>
    </div>
  );
};

export default Slider;
