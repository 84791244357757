import config from '../../../../config';
import { PROFILE_ENROLLEE_DYNAMIC_BLOCKS } from '../../../helpers/const';
import { getConst, getDefaultInitState } from '../../../helpers/functions';

const [ INITIAL_STATE_LOAD, INITIAL_STATE_SUCCESS, INITIAL_STATE_FAIL ] = getConst(PROFILE_ENROLLEE_DYNAMIC_BLOCKS);

export default function profile_enrollee_dynamic_blocks(state = getDefaultInitState(), action = {}) {
  switch (action.type) {
    case INITIAL_STATE_LOAD:
      return {
        ...state,
        loading: true
      };
    case INITIAL_STATE_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.result
      };
    case INITIAL_STATE_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error
      };

    default:
      return state;
  }
}

export function isLoaded(globalState) {
  return globalState.profile_enrollee_dynamic_blocks && globalState.profile_enrollee_dynamic_blocks.loaded;
}

export function load() {
  return {
    types: [ INITIAL_STATE_LOAD, INITIAL_STATE_SUCCESS, INITIAL_STATE_FAIL ],
    promise: (client) => client.get(`${config.apiHost}/profile/dynamic-blocks`)
  };
}

export function save(params) {
  return {
    types: [ INITIAL_STATE_LOAD, INITIAL_STATE_SUCCESS, INITIAL_STATE_FAIL ],
    promise: (client) => client.post(`${config.apiHost}/profile/save-dynamic-block`, params)
  };
}
