import React, { useState, useEffect } from 'react';
import Title from './Title';
import University from '../University/University';
import './Universities.scss';
import Margin from '../Common/Margin';
import apiClient from '../../helpers/apiClient';
import config from '../../config';

const client = apiClient(null, 'ru', null);

const Universities = () => {
  const [ info, setInfo ] = useState([]);

  useEffect(() => {
    client.get(`${config.apiHost}/universities/main`).then(
      res => setInfo(res.data)
    );
  }, []);

  return (
    <div className="main-page-universities">
      <div>
        <Title>Учебные заведения</Title>
      </div>
      <Margin margin={50}/>
      <div className="main-page-universities-list">
        {
          info.map((item, index) => (
            <University
              key={index}
              item={item}
            />))
        }
      </div>
      <Margin margin={40}/>
    </div>
  );
};

export default Universities;
