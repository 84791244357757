import React from 'react';
import InfoBlock from './InfoBlock';
import VideoBlock from './VideoBlock';
import './Info.scss';

const Info = () => {
  return (
    <div className="main-page-info">
      <InfoBlock/>
      <VideoBlock/>
    </div>
  );
};

export default Info;
