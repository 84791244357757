import React, { useRef } from 'react';
import { useDetectOutsideClick } from '../../utils/useDetectOutsideClick';
import './DropdownButton.scss';
import Button from './Button';

const DropdownButton = (
  {
    title,
    list,
    pushState,
  }
) => {
  const dropdownRef = useRef(null);
  const [ isActive, setIsActive ] = useDetectOutsideClick(dropdownRef, false);
  return (
    <div className="dropdown-button-wrap">
      <div
        style={{
          width: '190px',
        }}
      >
        <Button
          changed={() => setIsActive(!isActive)}
          style="orange"
        >
          { title }
        </Button>
      </div>
      <div>
        <nav
          ref={dropdownRef}
          className={`dropdown-button ${isActive ? 'active' : 'inactive'}`}
        >
          <ul>
            {
              list ? list.map((item, index) => (
                <li key={index}>
                  <span
                    onClick={async () => {
                      item.hideDropdown && setIsActive(false);
                      item.cb && await item.cb();
                      item.to && await pushState(`/ru${item.to}`);
                    }}
                  >
                    {item.name}
                  </span>
                </li>
              )) : null
            }
          </ul>
        </nav>

      </div>
    </div>
  );
};

export default DropdownButton;
