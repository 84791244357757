import React from 'react';
import { RiArrowUpSLine } from 'react-icons/ri';
import './back-to-top.scss';

const toTop = () => window.scrollTo({
  top: 0,
  behavior: 'smooth'
});

const BackToTop = ({ isScrolled }) => {
  const sh = isScrolled ? 'back_to_top_show' : null;
  return (
    <a
      onClick={toTop}
      className={`back_to_top ${sh}`}
      title="top"
    >
      <RiArrowUpSLine/>
    </a>
  );
};

export default BackToTop;
