import React from 'react';
import './EnterUniversity.scss';
import Image from '../Common/Image';
import Container from '../Common/Container';
import Margin from '../Common/Margin';
import EntranceOrderButton from '../Profile/Enrollee/Entrance/EntranceOrder/EntranceOrderButton';

const EnterUniversity = (
  {
    pushState,
    profile_enrollee_entrance_is_allowed,
  }
) => {
  return (
    <div className="main-page-enter-university">
      <Container>
        <div className="main-page-enter-university-inner">
          <div>
            <Image src="/images/main/Layer.svg"/>
          </div>
          <div className="main-page-enter-university-content">
            <h5>Поступить в учебное заведение</h5>
            <Margin margin={16}/>
            <span>Среднее время на регистрацию для поступления всего 5 минут</span>
          </div>
          <div style={{ width: '200px' }}>
            <EntranceOrderButton
              pushState={pushState}
              profile_enrollee_entrance_is_allowed={profile_enrollee_entrance_is_allowed}
            />
          </div>
        </div>
      </Container>
    </div>
  );
};

export default EnterUniversity;
