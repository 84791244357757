const INITIAL_STATE_LOAD = 'NEWS_SHOW_STATE_LOAD';
const INITIAL_STATE_SUCCESS = 'NEWS_SHOW_STATE_SUCCESS';
const INITIAL_STATE_FAIL = 'NEWS_SHOW_STATE_FAIL';

import config from '../../../config';

const initialState = { loaded: false};

export default function news_show(state = initialState, action = {}) {

  switch (action.type) {

    case INITIAL_STATE_LOAD:
      return { ...state, loading: true };
    case INITIAL_STATE_SUCCESS:
      return { ...state, loading: false, loaded: true, data: action.result };
    case INITIAL_STATE_FAIL:
      return { ...state, loading: false, loaded: false, error: action.error };

    default:
      return state;
  }
}

export function isLoaded(globalState) {
  return globalState.news_show && globalState.news_show.loaded;
}

export function load(slug) {
  return {
    types: [INITIAL_STATE_LOAD, INITIAL_STATE_SUCCESS, INITIAL_STATE_FAIL],
    promise: (client) => client.get(`${config.apiHost}/news/${slug}`)
  };
}