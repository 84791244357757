import React from 'react';
import './AnimationLoader.scss';

const AnimationLoader = ({ children }) => {
  return (
    <div className="animation-container">
      <svg viewBox="0 205 600 600">
        <symbol id="text">
          <text
            textAnchor="middle"
            x="50%"
            y="50%"
          >
            { children }
          </text>
        </symbol>
        <use
          xlinkHref="#text"
          className="text"
        >
        </use>
        <use
          xlinkHref="#text"
          className="text"
        >
        </use>
        <use
          xlinkHref="#text"
          className="text"
        >
        </use>
        <use
          xlinkHref="#text"
          className="text"
        >
        </use>
      </svg>
    </div>
  );
};

export default AnimationLoader;
