import React from 'react';
import { Player } from 'video-react';
import './PlayerWrapper.scss';

const PlayerWrapper = ({ url }) => {
  return (
    <div>
      <Player>
        <source src={url} />
      </Player>
    </div>
  );
};

export default PlayerWrapper;
