export function formatter(val) {
  return Number.isInteger(val) ? Intl.NumberFormat('ru-RU', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(val) : val;
}

export function createJSONStructure(obj) {
  Object.keys(obj)
    .forEach((k) => {
      const prop = k.split('.');

      if (prop.length > 1) {
        const last = prop.pop();
        prop.reduce((o, key) => {
          // eslint-disable-next-line no-param-reassign
          return o[key] = o[key] || {};
        }, obj)[last] = obj[k];
        // eslint-disable-next-line no-param-reassign
        delete obj[k];
      }
    });
  return obj;
}

export function getPercentage(a, b) {
  return `${parseInt(b * 100 / a, 10) }%`;
}
