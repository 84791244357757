import React from 'react';
import './Footer.scss';
import Logo from './Header/Logo';
import Container from '../Common/Container';
import Margin from '../Common/Margin';

const TextContainer = ({ children }) => <span className="footer-text">{children}</span>;

const Footer = () => {
  const margin = <Margin margin={5}/>;
  return (
    <div className="footer">
      <Margin margin={50}/>
      <Container>
        <div className="footer-top">
          <section>
            <Logo light/>
            <Margin margin={40}/>
            <TextContainer>
              DanQ - это платформа
              для онлайн поступления
              в учебное заведение.
            </TextContainer>
            <Margin margin={40}/>
            <div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center'
                }}
              >
                <TextContainer>тел: </TextContainer>
                <span
                  style={{
                    color: '#fff',
                    marginLeft: '5px'
                  }}
                >
                +77052630136
                </span>
              </div>
              {margin}
              <div>
                <TextContainer>email: </TextContainer>
                <span
                  style={{
                    color: '#fff',
                    marginLeft: '5px'
                  }}
                >
                  geny_0201@mail.ru
                </span>
              </div>
              {margin}
              <div>
                <TextContainer>адрес: </TextContainer>
                <span
                  style={{
                    color: '#fff',
                    marginLeft: '5px'
                  }}
                >
                  г.Алматы Айтике би 56
                </span>
              </div>
            </div>
          </section>
          <section>
            <Margin margin={60}/>
            <h6>Для абитуриентов</h6>
            <Margin margin={20}/>
            <TextContainer>Приёмная комиссия</TextContainer>
            {margin}
            <TextContainer>Бакалавриат</TextContainer>
            {margin}
            <TextContainer>Магистратура</TextContainer>
            {margin}
            <TextContainer>Докторантура</TextContainer>
          </section>
          <section>
            <Margin margin={60}/>
            <h6>Для студентов</h6>
            <Margin margin={20}/>
            <TextContainer>Приёмная комиссия</TextContainer>
            {margin}
            <TextContainer>Бакалавриат</TextContainer>
            {margin}
            <TextContainer>Магистратура</TextContainer>
            {margin}
            <TextContainer>Докторантура</TextContainer>
            {margin}
            <TextContainer>Курсы</TextContainer>
            {margin}
            <TextContainer>Образовательные программы</TextContainer>
          </section>
          <section>
            <Margin margin={60}/>
            <h6>Для учебных заведений</h6>
            <Margin margin={20}/>
            <TextContainer>Приёмная комиссия</TextContainer>
            {margin}
            <TextContainer>Бакалавриат</TextContainer>
            {margin}
            <TextContainer>Магистратура</TextContainer>
            {margin}
          </section>
        </div>
        <Margin margin={50}/>
        <div className="footer-bottom">
          <section>
            <TextContainer>Copyright © 2021, DanQ</TextContainer>
            <TextContainer>О проекте</TextContainer>
            <TextContainer>Сотрудничество</TextContainer>
            <TextContainer>Контакты</TextContainer>
            <TextContainer>Конфиденциальность</TextContainer>
            <TextContainer>Правила</TextContainer>
          </section>
          <section></section>
        </div>
      </Container>
      <Margin margin={50}/>
    </div>
  );
};

export default Footer;
