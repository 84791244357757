import React, { Component } from 'react';
import Button from '../../../../Common/Button';

class EntranceOrderButton extends Component {
  render() {
    const {
      pushState,
      profile_enrollee_entrance_is_allowed,
    } = this.props;

    return profile_enrollee_entrance_is_allowed ? (
      <Button
        style={profile_enrollee_entrance_is_allowed ? 'green' : ''}
        disabled={ !profile_enrollee_entrance_is_allowed }
        changed={() => {
          pushState('/ru/profile/enrollee/entrance-order');
        }}
      >
        Поступить
      </Button>
    ) : null;
  }
}

export default EntranceOrderButton;
