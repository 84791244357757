import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { asyncConnect } from 'redux-connect';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import LoadingOverlay from 'react-loading-overlay';
import config from './config';
import {
  isLoaded as isRefFacultiesLoaded,
  load as loadRefFaculties
} from './redux/modules/refs/faculties';
import {
  isLoaded as isRefSpecialitiesLoaded,
  load as loadRefSpecialities
} from './redux/modules/refs/specialities';
import {
  isLoaded as isRefEducationPeriodsLoaded,
  load as loadRefEducationPeriods
} from './redux/modules/refs/education_periods';
import {
  isLoaded as isRefEducationProgramsLoaded,
  load as loadRefEducationPrograms
} from './redux/modules/refs/education_programs';
import {
  isLoaded as isRefDegreesLoaded,
  load as loadRefDegrees
} from './redux/modules/refs/degrees';
import {
  isLoaded as isRefSubjectsLoaded,
  load as loadRefSubjects
} from './redux/modules/refs/subjects';
import {
  isLoaded as isRefCountriesLoaded,
  load as loadRefCountries
} from './redux/modules/refs/countries';
import {
  isLoaded as isRefRegionsLoaded,
  load as loadRefRegions
} from './redux/modules/refs/regions';
import {
  isLoaded as isRefCitiesLoaded,
  load as loadRefCities
} from './redux/modules/refs/cities';
import {
  isLoaded as isRefLocalitiesLoaded,
  load as loadRefLocalities
} from './redux/modules/refs/localities';
import {
  isLoaded as isRefNationsLoaded,
  load as loadRefNations
} from './redux/modules/refs/nations';
import {
  isLoaded as isRefUniversitiesLoaded,
  load as loadRefUniversities
} from './redux/modules/refs/universities';
import {
  isLoaded as isAuthLoaded,
  load as loadAuth,
  logout
} from './redux/modules/auth/auth';
import { setNeedAuth } from './redux/modules/auth/need_auth';
import {
  subscribe as subscribeEducation,
  unsubscribe as unsubscribeEducation
} from './redux/modules/subscribe/universities';
import {
  isLoaded as isEntranceIsAllowedLoaded,
  load as loadEntranceIsAllowed
} from './redux/modules/profile/enrollee/entrance_is_allowed';
import { close_notify, show_notify } from './redux/modules/notify/notify';
import { upload as uploadImage, destroy as destroyImage } from './redux/modules/upload/image';
import { upload as uploadFile, destroy as destroyFile } from './redux/modules/upload/file';
import AnimationLoader from './components/Layout/AnimationLoader';
import BackToTop from './components/Layout/BackToTop';
import NeedAuth from './components/Auth/NeedAuth';
import Header from './components/Layout/Header';
import Footer from './components/Layout/Footer';
import Page from './components/Common/Page';
import NotifyBlock from './components/Common/NotifyBlock';

@asyncConnect([ {
  promise: (props) => {
    const promises = [];

    const {
      store: {
        dispatch, getState
      }
    } = props;

    if (!isAuthLoaded(getState())) {
      promises.push(dispatch(loadAuth()));
    }

    // if (!isRefFacultiesLoaded(getState())) {
    //   promises.push(dispatch(loadRefFaculties()));
    // }

    if (!isRefSpecialitiesLoaded(getState())) {
      promises.push(dispatch(loadRefSpecialities()));
    }

    if (!isRefEducationPeriodsLoaded(getState())) {
      promises.push(dispatch(loadRefEducationPeriods()));
    }

    if (!isRefEducationProgramsLoaded(getState())) {
      promises.push(dispatch(loadRefEducationPrograms()));
    }

    if (!isRefDegreesLoaded(getState())) {
      promises.push(dispatch(loadRefDegrees()));
    }

    if (!isRefSubjectsLoaded(getState())) {
      promises.push(dispatch(loadRefSubjects()));
    }

    if (!isRefCountriesLoaded(getState())) {
      promises.push(dispatch(loadRefCountries()));
    }

    if (!isRefRegionsLoaded(getState())) {
      promises.push(dispatch(loadRefRegions()));
    }

    if (!isRefCitiesLoaded(getState())) {
      promises.push(dispatch(loadRefCities()));
    }

    if (!isRefLocalitiesLoaded(getState())) {
      promises.push(dispatch(loadRefLocalities()));
    }

    if (!isRefNationsLoaded(getState())) {
      promises.push(dispatch(loadRefNations()));
    }

    if (!isRefUniversitiesLoaded(getState())) {
      promises.push(dispatch(loadRefUniversities()));
    }

    if (!isEntranceIsAllowedLoaded(getState())) {
      promises.push(dispatch(loadEntranceIsAllowed()));
    }

    return Promise.all(promises);
  }
} ])

@connect(
  state => ({
    need_auth: state.need_auth,
    notify: state.notify.notify,
    loading: state.loading.loading,
    user: state.auth.user,
    // ref_faculties: state.ref_faculties.data.data,
    ref_specialities: state.ref_specialities.data.data,
    ref_education_periods: state.ref_education_periods.data.data,
    ref_education_programs: state.ref_education_programs.data.data,
    ref_degrees: state.ref_degrees.data.data,
    ref_subjects: state.ref_subjects.data.data,
    ref_countries: state.ref_countries.data.data,
    ref_regions: state.ref_regions.data.data,
    ref_cities: state.ref_cities.data.data,
    ref_localities: state.ref_localities.data.data,
    ref_nations: state.ref_nations.data.data,
    ref_universities: state.ref_universities.data.data,
    profile_enrollee_entrance_is_allowed: state.profile_enrollee_entrance_is_allowed.data.result,
  }),
  {
    pushState: push,
    logout,
    setNeedAuth,
    show_notify,
    close_notify,
    uploadImage,
    uploadFile,
    destroyImage,
    destroyFile,
    subscribeEducation,
    unsubscribeEducation,
    loadEntranceIsAllowed,
  }
)

export default class App extends Component {
  static contextTypes = {
    store: PropTypes.object.isRequired,
  };

  state = {
    isScrolled: false,
  }

  componentDidMount() {
    window && window.addEventListener('scroll', this.trackScroll);
  }

  componentWillUnmount() {
    window && window.removeEventListener('scroll', this.trackScroll);
  }

  trackScroll = () => {
    const scrolled = window.pageYOffset;
    const coords = (document.documentElement.clientHeight / 6);

    let isScrolled = false;

    if (scrolled > coords) {
      isScrolled = true;
    }

    if (scrolled < coords) {
      isScrolled = false;
    }

    this.setState({ isScrolled });
  }

  render() {
    if (this.props.need_auth.state) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }

    return (
      <LoadingOverlay
        active={this.props.loading}
        spinner={<AnimationLoader>DanQ</AnimationLoader>}
        styles={
          {
            overlay: (base) => ({
              ...base,
              zIndex: 1000000,
            }),
            content: () => ({
              position: 'fixed',
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
            }),
            spinner: (base) => ({
              ...base,
              width: '100px',
              position: 'fixed',
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
            })
          }
        }
      >
        <div id="app">
          <Helmet
            {...config.app.head}
            htmlAttributes={{ lang: 'ru' }}
          />
          <NotifyBlock {...this.props}/>
          <NeedAuth {...this.props}/>
          <Header
            {...this.props}
            isScrolled={this.state.isScrolled}
          />
          <Page>
            {
              React.cloneElement(
                this.props.children || <div/>, {
                  ...this.props,
                  isScrolled: this.state.isScrolled
                }
              )
            }
          </Page>
          <Footer/>
          <BackToTop
            isScrolled={this.state.isScrolled}
          />
        </div>
      </LoadingOverlay>
    );
  }
}
