export const AUTH = 'AUTH';
export const LOGOUT = 'AUTH';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const RESET_PASSWORD = 'RESET_PASSWORD';

export const SPECIALTIES = 'SPECIALTIES';
export const SPECIALTY = 'SPECIALTY';

export const UNIVERSITIES = 'UNIVERSITIES';
export const UNIVERSITY = 'UNIVERSITY';
export const UNIVERSITY_BLOCK_ADMISSION_RULES = 'UNIVERSITY_BLOCK_ADMISSION_RULES';
export const UNIVERSITY_BLOCK_CONTACT_INFO = 'UNIVERSITY_BLOCK_CONTACT_INFO';
export const UNIVERSITY_BLOCK_ENROLLEE_STEPS = 'UNIVERSITY_BLOCK_ENROLLEE_STEPS';
export const UNIVERSITY_BLOCK_FAQ = 'UNIVERSITY_BLOCK_FAQ';
export const UNIVERSITY_BLOCK_HISTORY = 'UNIVERSITY_BLOCK_HISTORY';
export const UNIVERSITY_BLOCK_HOTLINE = 'UNIVERSITY_BLOCK_HOTLINE';
export const UNIVERSITY_BLOCK_MAIN_CALL_CENTER = 'UNIVERSITY_BLOCK_MAIN_CALL_CENTER';
export const UNIVERSITY_BLOCK_MISSION = 'UNIVERSITY_BLOCK_MISSION';
export const UNIVERSITY_BLOCK_STRATEGIC_OBJECTIVES = 'UNIVERSITY_BLOCK_STRATEGIC_OBJECTIVES';
export const UNIVERSITY_BLOCK_TRAINING_COURSES = 'UNIVERSITY_BLOCK_TRAINING_COURSES';
export const UNIVERSITY_BLOCK_VISION = 'UNIVERSITY_BLOCK_VISION';
export const UNIVERSITY_BLOCK_WORTH = 'UNIVERSITY_BLOCK_WORTH';

export const NEWS_LIKE = 'NEWS_LIKE';

export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';

export const PROFILE_ENROLLEE_DYNAMIC_BLOCKS = 'PROFILE_ENROLLEE_DYNAMIC_BLOCKS';
export const PROFILE_ENROLLEE_ENTRANCE_STEPS = 'PROFILE_ENROLLEE_ENTRANCE_STEPS';
export const PROFILE_ENROLLEE_ENTRANCE_STEP = 'PROFILE_ENROLLEE_ENTRANCE_STEP';
export const PROFILE_ENROLLEE_ENTRANCE_LIST = 'PROFILE_ENROLLEE_ENTRANCE_LIST';
export const PROFILE_ENROLLEE_ENTRANCE_WITHDRAW = 'PROFILE_ENROLLEE_ENTRANCE_WITHDRAW';
export const PROFILE_ENROLLEE_ENTRANCE_SHOW = 'PROFILE_ENROLLEE_ENTRANCE_SHOW';
export const PROFILE_ENROLLEE_ENTRANCE_ORDER = 'PROFILE_ENROLLEE_ENTRANCE_ORDER';
export const PROFILE_ENROLLEE_ENTRANCE_IS_ALLOWED = 'PROFILE_ENROLLEE_ENTRANCE_IS_ALLOWED';
export const PROFILE_ENROLLEE_ANKETA_IS_ALLOWED = 'PROFILE_ENROLLEE_ANKETA_IS_ALLOWED';
export const PROFILE_ENROLLEE_BALANCE_HISTORY = 'PROFILE_ENROLLEE_BALANCE_HISTORY';
export const PROFILE_ENROLLEE_FEEDBACK = 'PROFILE_ENROLLEE_FEEDBACK';

export const PROFILE_UNIVERSITY_INFO = 'PROFILE_UNIVERSITY_INFO';
export const PROFILE_UNIVERSITY_EMPLOYERS = 'PROFILE_UNIVERSITY_EMPLOYERS';
export const PROFILE_UNIVERSITY_ENTRANCE_LIST = 'PROFILE_UNIVERSITY_ENTRANCE_LIST';
export const PROFILE_UNIVERSITY_ENTRANCE_SHOW = 'PROFILE_UNIVERSITY_ENTRANCE_SHOW';
export const PROFILE_UNIVERSITY_ENTRANCE_HISTORY = 'PROFILE_UNIVERSITY_ENTRANCE_HISTORY';
export const PROFILE_UNIVERSITY_ENTRANCE_FIX_FIELD = 'PROFILE_UNIVERSITY_ENTRANCE_FIX_FIELD';
export const PROFILE_UNIVERSITY_ENTRANCE_REJECT = 'PROFILE_UNIVERSITY_ENTRANCE_REJECT';
export const PROFILE_UNIVERSITY_USERS = 'PROFILE_UNIVERSITY_USERS';
export const PROFILE_UNIVERSITY_SPECIALITIES = 'PROFILE_UNIVERSITY_SPECIALITIES';
export const PROFILE_UNIVERSITY_SPECIALITIES_LIST = 'PROFILE_UNIVERSITY_SPECIALITIES_LIST';
export const PROFILE_UNIVERSITY_FACULTIES = 'PROFILE_UNIVERSITY_FACULTIES';
export const PROFILE_UNIVERSITY_FACULTIES_LIST = 'PROFILE_UNIVERSITY_FACULTIES_LIST';
export const PROFILE_UNIVERSITY_EDUCATION_PROGRAMS = 'PROFILE_UNIVERSITY_EDUCATION_PROGRAMS';
export const PROFILE_UNIVERSITY_EDUCATION_PROGRAM = 'PROFILE_UNIVERSITY_EDUCATION_PROGRAM';
export const PROFILE_UNIVERSITY_NEWS = 'PROFILE_UNIVERSITY_NEWS';
export const PROFILE_UNIVERSITY_NEWS_UNIT = 'PROFILE_UNIVERSITY_NEWS_UNIT';
export const PROFILE_UNIVERSITY_BLOCKS = 'PROFILE_UNIVERSITY_BLOCKS';
export const PROFILE_UNIVERSITY_TESTS = 'PROFILE_UNIVERSITY_TESTS';

export const SUBSCRIBE_UNIVERSITIES = 'SUBSCRIBE_UNIVERSITIES';
export const SUBSCRIBE_NEWS = 'SUBSCRIBE_NEWS';

export const TEST_START = 'TEST_START';
export const TEST_FINISH = 'TEST_FINISH';
export const TEST_QUESTIONS = 'TEST_QUESTIONS';
export const TEST_ANSWER = 'TEST_ANSWER';

export const UPLOAD_IMAGE = 'UPLOAD_IMAGE';

export const UPLOAD_FILE = 'UPLOAD_FILE';

export const REF_ROLES = 'REF_ROLES';

export const REF_FACULTIES = 'REF_FACULTIES';
export const REF_SPECIALITIES = 'REF_SPECIALITIES';
export const REF_EDUCATION_PERIODS = 'REF_EDUCATION_PERIODS';
export const REF_EDUCATION_PROGRAMS = 'REF_EDUCATION_PROGRAMS';
export const REF_COUNTRIES = 'REF_COUNTRIES';
export const REF_CITIES = 'REF_CITIES';
export const REF_NATIONS = 'REF_NATIONS';
export const REF_EDUCATION_LEVELS = 'REF_EDUCATION_LEVELS';
export const REF_PROFILE_DYNAMIC_BLOCKS = 'REF_PROFILE_DYNAMIC_BLOCKS';
export const REF_DEGREES = 'REF_DEGREES';
export const REF_SUBJECTS = 'REF_SUBJECTS';
export const REF_PROFILE_TYPES = 'REF_PROFILE_TYPES';
export const REF_UNIVERSITIES = 'REF_UNIVERSITIES';
export const REF_GENDERS = 'REF_GENDERS';
export const REF_MARTIAL_STATUSES = 'REF_MARTIAL_STATUSES';
export const REF_VIDEO_LINK_TYPES = 'REF_VIDEO_LINK_TYPES';
export const REF_ADDRESS_TYPES = 'REF_ADDRESS_TYPES';
export const REF_PAYMENT_TYPES = 'REF_PAYMENT_TYPES';
export const REF_EDUCATION_FORMS = 'REF_EDUCATION_FORMS';
export const REF_DOCUMENT_STATUSES = 'REF_DOCUMENT_STATUSES';
export const REF_DOCUMENT_TYPES = 'REF_DOCUMENT_TYPES';
export const REF_REGIONS = 'REF_REGIONS';
export const REF_LOCALITIES = 'REF_LOCALITIES';
export const REF_PARENT_TYPES = 'REF_PARENT_TYPES';
export const REF_EDUCATION_DOCUMENTS = 'REF_EDUCATION_DOCUMENTS';
export const REF_EDUCATION_TYPES = 'REF_EDUCATION_TYPES';
export const REF_EDUCATION_INSTITUTION_TYPES = 'REF_EDUCATION_INSTITUTION_TYPES';
export const REF_EDUCATION_LOCALES = 'REF_EDUCATION_LOCALES';
export const REF_EDUCATION_CERT_TYPES = 'REF_EDUCATION_CERT_TYPES';
export const REF_ANKETA_MEDICAL_TYPES = 'REF_ANKETA_MEDICAL_TYPES';
export const REF_LANGUAGE_PROFICIENCY = 'REF_LANGUAGE_PROFICIENCY';
export const REF_LOCALES = 'REF_LOCALES';
export const REF_PAYER_TYPES = 'REF_PAYER_TYPES';
export const REF_PRICE_RANGE = 'REF_PRICE_RANGE';
export const REF_SOCIAL_CATEGORIES = 'REF_SOCIAL_CATEGORIES';
export const REF_PREEMPTIVE_RIGHTS = 'REF_PREEMPTIVE_RIGHTS';
export const REF_AWARDS = 'REF_AWARDS';
