import React from 'react';
import './Header.scss';
import Logo from './Header/Logo';
import Navs from './Header/Navs';
import Settings from './Header/Settings';
import Container from '../Common/Container';

const Header = (props) => {
  const {
    location: {
      pathname
    },
    pushState,
    user,
    logout,
    isScrolled,
    profile_enrollee_entrance_is_allowed,
  } = props;

  const s = pathname === '/' ? {
    border: isScrolled && '1px solid #e6e9f5'
  } : {
    border: '1px solid #e6e9f5'
  };

  const h = isScrolled ? '70px' : '140px';

  return (
    <header
      className="header"
      style={{
        ...s,
        height: h,
      }}
    >
      <Container>
        <div
          className="header-inner"
          style={{
            height: h
          }}
        >
          <Logo/>
          <Navs isScrolled={isScrolled}/>
          <Settings
            pushState={pushState}
            user={user}
            profile_enrollee_entrance_is_allowed={profile_enrollee_entrance_is_allowed}
            logout={logout}
          />
        </div>
      </Container>
    </header>
  );
};

export default Header;
