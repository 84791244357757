import React, { Component } from 'react';
import './Notify.scss';
import Notify from './Notify.js';

export default class NotifyBlock extends Component {
  render() {
    const { notify } = this.props;

    return (
      <div className="notify_block">
        {
          notify.map((item) => (
            <Notify
              key={item.id}
              {...this.props}
              item={item}
            />
          ))
        }
      </div>
    );
  }
}
