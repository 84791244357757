import React from 'react';
import { Link } from 'react-router';
import Image from '../Common/Image';
import Margin from '../Common/Margin';
import { formatter } from '../../utils/functions';
import './Item.scss';

const Item = ({ item }) => {
  return item ? (
    <Link
      to={`/ru/specialty/${item.id}`}
      onlyActiveOnIndex={false}
    >
      <div className="main-page-specialty">
        <div className="main-page-specialty-img">
          <Image
            src={item.avatar_id ? item.avatar.origin : null}
          />
          {item.education_period && <span>{item.education_period}</span>}
        </div>
        <div className="main-page-specialty-content">
          <h4>{item.name}</h4>
          <Margin margin={10}/>
          <span>{`От ${item.price ? formatter(item.price) : 0} тг.`}</span>
          <div className="main-page-specialty-content-footer">
            <span className="main-page-specialty-content-footer-university">
              {item.university.name}
            </span>
            <Margin margin={5}/>
            <span className="main-page-specialty-content-footer-location">
              {`${item.university.city}, ${item.university.country}`}
            </span>
          </div>
        </div>
      </div>
    </Link>
  ) : null;
};

export default Item;
