import React, { Component } from 'react';
import { MdClose } from 'react-icons/md';
import './Notify.scss';
import Margin from './Margin';


export default class Notify extends Component {
  constructor(props) {
    super(props);

    let width = 100;
    const { close_notify } = this.props;
    const { id } = this.props.item;
    const vm = this;

    function frame() {
      if (width <= 0) {
        setTimeout(() => close_notify(id), 400);
        clearInterval(vm.state.interval);
        vm.toggleActiveState();
      } else {
        width--;
        vm.setWidthState(width);
      }
    }

    this.state = {
      width: '100%',
      active: false,
      interval: setInterval(frame, 150),
    };
    this.setWidthState = this.setWidthState.bind(this);
  }

  componentDidMount() {
    setTimeout(() => this.toggleActiveState(), 400);
  }

  componentWillUnmount() {
    this.setState({ interval: clearInterval(this.state.interval) });
  }

  setWidthState(width) {
    this.setState({ width: `${width }%` });
  }

  toggleActiveState() {
    this.setState({ active: !this.state.active });
  }

  render() {
    const {
      style = 'success', title, text, id
    } = this.props.item;
    const { close_notify } = this.props;

    let titleTemp = title;

    if (!titleTemp) {
      switch (style) {
        case 'info':
          titleTemp = 'Информация';
          break;
        case 'warning':
          titleTemp = 'Важно';
          break;
        case 'error':
          titleTemp = 'Ощибка';
          break;
        case 'success':
          titleTemp = 'Успех';
          break;
        default:
          titleTemp = 'Успех';
          break;
      }
    }

    return (
      <div
        className={`
        notify_wrapper 
        ${this.state.active ? 'notify_wrapper_active' : ''}
        ${style}
       `}
      >
        <div className="notify">
          <span
            className="notify_close"
            onClick={() => close_notify(id)}
          >
            <MdClose/>
          </span>

          <span className="notify_title">{titleTemp}</span>
          {
            text && (
              <>
                <Margin margin={10}/>
                <p>{text}</p>
              </>
            )
          }
        </div>
        <div className="notify_progress">
          <div
            style={{ width: this.state.width }}
            className="notify_progress_bar"
          />
        </div>
      </div>
    );
  }
}
