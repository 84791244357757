import React from 'react';
import Image from '../Common/Image';
import './HowIsItWork.scss';
import Margin from '../Common/Margin';
import Title from './Title';

const l = [
  {
    title: 'Регистрация на платформу',
    img: 'edriuew.svg',
  },
  {
    title: 'Поиск специальности и учебного заведение',
    img: 'grdsgr.svg',
  },
  {
    title: 'Подача заявления на поступление',
    img: 'teyebn.svg',
  },
  {
    title: 'Зачисление в учебное заведение',
    img: 'cxvxtrer.svg',
  }
];

const HowIsItWork = () => {
  return (
    <div className="main-page-how-is-it-work">
      <div className="main-page-how-is-it-work-header">
        <span/>
        <Title>Как это работает</Title>
        <span>Посмотрите видеоинструкцию ниже</span>
      </div>
      <Margin margin={80}/>
      <div className="main-page-how-is-it-work-images">
        {
          l.map((item, index) => (
            <div key={index}>
              <Image src={`/images/main/${item.img}`}/>
              <h6><span>{index + 1}.</span> {item.title}</h6>
            </div>
          ))
        }
      </div>
    </div>
  );
};

export default HowIsItWork;
