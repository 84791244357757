const INITIAL_STATE = 'NEED_AUTH_STATE';

const initialState = {
  state: false,
  title: 'Необходимо авторизоваться'
};

export default function need_auth(state = initialState, action = {}) {
  switch (action.type) {
    case INITIAL_STATE:
      return {
        state: action.need_auth,
        title: action.title ? action.title : initialState.title
      };

    default:
      return state;
  }
}

export function setNeedAuth(state = false, title) {
  return {
    type: INITIAL_STATE,
    need_auth: state,
    title,
  };
}
