import axios from 'axios';
import config from '../config';

export default function apiClient(req, locale = 'kk', token = '') {
  const headerConfig = {
    Accept: 'application/json'
  };

  if (token) {
    headerConfig.Authorization = `Bearer ${token}`;
  }

  if (locale) {
    headerConfig['x-locale'] = locale;
  }

  const instance = axios.create({
    baseURL: `http://${config.apiHost}`,
    headers: {
      post: headerConfig,
      delete: headerConfig,
      get: headerConfig
    }
  });

  instance.interceptors.request.use(
    conf => conf,
    error => Promise.reject(error)
  );

  instance.interceptors.response.use(
    response => response.data,
    error => Promise.reject(error.response ? error.response : error)
  );

  return instance;
}
