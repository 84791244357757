import React from 'react';
import Button from '../../Common/Button';
import './Settings.scss';
import ProfileDropdown from './ProfileDropdown';
import EntranceOrderButton from '../../Profile/Enrollee/Entrance/EntranceOrder/EntranceOrderButton';

const Settings = (
  {
    pushState,
    user,
    logout,
    profile_enrollee_entrance_is_allowed,
  }
) => {
  return (
    <div className="settings">
      {/* <div style={{ width: '80px' }}> */}
      {/*  <Button>Рус</Button> */}
      {/* </div> */}
      {
        user ? (
          <ProfileDropdown
            user={user}
            pushState={pushState}
            logout={logout}
          />
        ) : (
          <div style={{ width: '84px' }}>
            <Button changed={() => pushState('/ru/login')}>Войти</Button>
          </div>
        )
      }

      {
        profile_enrollee_entrance_is_allowed ? (
          <div style={{ width: '140px' }}>
            <EntranceOrderButton
              pushState={pushState}
              profile_enrollee_entrance_is_allowed={profile_enrollee_entrance_is_allowed}
            />
          </div>
        ) : null
      }

    </div>
  );
};

export default Settings;
