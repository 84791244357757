import React, { useState, useEffect } from 'react';
import './InfoBlock.scss';
import CustomHr from '../Common/CustomHr';
import Margin from '../Common/Margin';
import apiClient from '../../helpers/apiClient';
import config from '../../config';

const client = apiClient(null, 'ru', null);

const InfoBlock = () => {
  const [ educationProgramCount, setEducation ] = useState(0);
  const [ educationUniversityCount, setUniversit ] = useState(0);

  useEffect(() => {
    client.get(`${config.apiHost}/education-programs/count`).then(
      res => setEducation(res.result)
    );
    client.get(`${config.apiHost}/universities/count`).then(
      res => setUniversit(res.result)
    );
  }, []);

  return (
    <div className="main-page-info-block">
      <div className="main-page-info-block-top">
        <div>
          <h6>{educationProgramCount}</h6>
          <Margin margin={20}/>
          <p>{`более ${educationProgramCount} специальностей`}</p>
        </div>
        <div>
          <h6 style={{ color: '#c1e063' }}>{educationUniversityCount}</h6>
          <Margin margin={20}/>
          <p>{`более ${educationUniversityCount} учебных заведений`}</p>
        </div>
      </div>
      <Margin margin={40}/>
      <div style={{ width: '100px' }}>
        <CustomHr/>
      </div>
      <Margin margin={40}/>
      <div className="main-page-info-block-bottom">
        <h6>Студенты и учебные заведения</h6>
        <Margin margin={18}/>
        <p>
          Учебные программы охватывают теоретические основы, знания управленческих моделей и структур, которые помогут будущим профессионалам
        </p>
      </div>
    </div>
  );
};

export default InfoBlock;
