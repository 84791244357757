export default Object.assign({
  host: process.env.REACT_APP_HOST ? process.env.REACT_APP_HOST : 'localhost',
  port: process.env.REACT_APP_PORT ? process.env.REACT_APP_PORT : 3000,
  apiHost: process.env.REACT_APP_API_HOST ? process.env.REACT_APP_API_HOST : 'https://danq.kz/api/v1',
  app: {
    head: {
      titleTemplate: '%s',
      htmlAttributes: {
        lang: 'kk'
      },
      meta: [
        {charset: 'utf-8'},
        {'http-equiv': 'X-UA-Compatible', content: 'IE=edge,chrome=1'},
        {name: 'viewport', content: 'width=device-width,initial-scale=1'},
        {name: 'description', content: ''},
      ],
      link: [
        {rel: 'shortcut icon', type: 'image/x-icon', href: '/images/logo/favicon.png'},
        {rel: 'manifest', href: '/manifest.json'},
      ],
    }
  },
});
