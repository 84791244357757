import config from '../../../../config';
import { getConst, getDefaultInitState } from '../../../helpers/functions';
import { PROFILE_UNIVERSITY_EDUCATION_PROGRAMS } from '../../../helpers/const';

// eslint-disable-next-line max-len
const [ INITIAL_STATE_LOAD, INITIAL_STATE_SUCCESS, INITIAL_STATE_FAIL ] = getConst(PROFILE_UNIVERSITY_EDUCATION_PROGRAMS);

export default function profile_university_education_programs(state = getDefaultInitState(), action = {}) {
  switch (action.type) {
    case INITIAL_STATE_LOAD:
      return {
        ...state,
        loading: true
      };
    case INITIAL_STATE_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.result
      };
    case INITIAL_STATE_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error
      };

    default:
      return state;
  }
}

export function isLoaded(globalState) {
  return globalState.profile_university_education_programs && globalState.profile_university_education_programs.loaded;
}

export function load() {
  return {
    types: [ INITIAL_STATE_LOAD, INITIAL_STATE_SUCCESS, INITIAL_STATE_FAIL ],
    promise: (client) => client.get(`${config.apiHost}/universities/education-programs`)
  };
}
