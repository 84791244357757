import React from 'react';
import DropdownButton from '../../Common/DropdownButton';
import '../../Common/DropdownButton.scss';

const ProfileDropdown = (
  {
    user,
    pushState,
    logout,
  }
) => {
  const l = [ {
    name: 'Личный кабинет',
    to: '/profile/enrollee/settings',
    cb: null,
    hideDropdown: true,
  }, {
    name: 'Личный кабинет университета',
    to: '/profile/university/info',
    cb: null,
    hideDropdown: true,
  }, {
    name: 'Выйти',
    to: null,
    cb: () => {
      logout().then(
        res => res.status === 'success' && window.location.reload(),
        err => {}
      );
    },
  } ];
  return (
    <DropdownButton
      title="Личный кабинет"
      list={l}
      pushState={pushState}
    />
  );
};

export default ProfileDropdown;
