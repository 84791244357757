import React from 'react';
import ReactDOM from 'react-dom';
import 'moment/locale/ru';
import 'moment/locale/kk';
import cookie from 'cookie';
import {
  applyRouterMiddleware,
  browserHistory,
  match,
  Router
} from 'react-router';
import { bindActionCreators } from 'redux';
import {
  replace,
  syncHistoryWithStore
} from 'react-router-redux';
import { ReduxAsyncConnect } from 'redux-connect';
import { Provider } from 'react-redux';
import { useScroll } from 'react-router-scroll';
import reportWebVitals from './reportWebVitals';
import createStore from './redux/create';
import apiClient from './helpers/apiClient';
import getRoutes from './routes';
import './styles/reset.css';
import './styles/index.css';
import './styles/scroll.css';
import './styles/draft-wysiwyg.css';

const token = cookie.parse(document.cookie).token || '';
let locale = cookie.parse(document.cookie).locale ? cookie.parse(document.cookie).locale : 'kk';

(async () => {
  const s = await window.location.pathname.split('/');
  locale = s[1] ? s[1] : locale;
  const dest = document && document.getElementById('root');
  const client = apiClient(null, locale, token);

  const store = createStore(
    browserHistory,
    client,
    {}
  );

  const redirect = bindActionCreators(replace, store.dispatch);
  const history = syncHistoryWithStore(browserHistory, store);

  const renderRouter = props => (
    <ReduxAsyncConnect
      {...props}
      helpers={{
        client,
        redirect
      }}
      filter={item => !item.deferred}
      render={applyRouterMiddleware(useScroll())}
    />
  );

  const render = routes => {
    match({
      history,
      routes
    }, (error, redirectLocation, renderProps) => {
      ReactDOM.hydrate(
        <Provider
          store={store}
          key="provider"
        >
          <Router
            {...renderProps}
            render={renderRouter}
            history={history}
          >
            {routes}
          </Router>
        </Provider>,
        dest
      );
    });
  };

  render(getRoutes(store));
})();

reportWebVitals();
