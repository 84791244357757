import React from 'react';
import './Image.scss';

const Image = (
  {
    src,
    alt,
    title,
    style = {},
  }
) => {
  return (
    <div className="Image">
      <img
        src={!src ? '/images/default/picture.svg' : src}
        alt={!alt ? process.env.REACT_APP_NAME : alt}
        title={!title ? process.env.REACT_APP_NAME : title}
        loading="lazy"
        style={{
          ...style,
        }}
      />
    </div>
  );
};

export default Image;
