import React from 'react';
import { Link } from 'react-router';
import Image from '../Common/Image';
import './University.scss';
import Margin from '../Common/Margin';

const University = ({ item }) => {
  return (
    <Link
      to={`/ru/university/${item.slug}`}
      onlyActiveOnIndex={false}
    >
      <div className="main-page-university">
        <div>
          <Image
            src={item.logotype.origin}
            alt={item.name}
            title={item.name}
          />
        </div>
        <div>
          <Margin margin={20}/>
          <h4 className="main-page-university-title">{item.name}</h4>
          <Margin margin={14}/>
          <span className="main-page-university-location">{item.location}</span>
          <Margin margin={14}/>
          <div className="main-page-university-speciality-footer">
            <span>{item.specialities_count}</span>
          Все специальности
          </div>
        </div>
      </div>
    </Link>
  );
};

export default University;
