import React from 'react';
import './Container.scss';

const Container = (props) => {
  return (
    <div className="Container">
      <div>
        { props.children }
      </div>
    </div>
  );
};

export default Container
