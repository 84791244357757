import config from '../../../../config';
import { getConst, getDefaultInitState } from '../../../helpers/functions';
import { PROFILE_UNIVERSITY_EDUCATION_PROGRAM } from '../../../helpers/const';

// eslint-disable-next-line max-len
const [ INITIAL_STATE_LOAD, INITIAL_STATE_SUCCESS, INITIAL_STATE_FAIL ] = getConst(PROFILE_UNIVERSITY_EDUCATION_PROGRAM);

export default function profile_university_education_program(state = getDefaultInitState(), action = {}) {
  switch (action.type) {
    case INITIAL_STATE_LOAD:
      return {
        ...state,
        loading: true
      };
    case INITIAL_STATE_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.result
      };
    case INITIAL_STATE_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error
      };

    default:
      return state;
  }
}

export function isLoaded(globalState) {
  return globalState.profile_university_education_program && globalState.profile_university_education_program.loaded;
}

export function load(id) {
  return {
    types: [ INITIAL_STATE_LOAD, INITIAL_STATE_SUCCESS, INITIAL_STATE_FAIL ],
    promise: (client) => client.get(`${config.apiHost}/universities/education-programs/edit/${id}`)
  };
}

export function saveEducationProgram(params, id) {
  const route = id ? `update/${id}` : 'store';
  return {
    types: [ INITIAL_STATE_LOAD, INITIAL_STATE_SUCCESS, INITIAL_STATE_FAIL ],
    promise: (client) => client.post(`${config.apiHost}/universities/education-programs/${route}`, params)
  };
}

export function deleteEducationProgram(id) {
  return {
    types: [ INITIAL_STATE_LOAD, INITIAL_STATE_SUCCESS, INITIAL_STATE_FAIL ],
    promise: (client) => client.post(`${config.apiHost}/universities/education-programs/delete/${id}`)
  };
}
