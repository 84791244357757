import React from 'react';
import { Link } from 'react-router';
import './Logo.scss';

const Logo = (
  {
    light = false
  }
) => {
  return (
    <div className={`header-logo ${light ? 'header-logo-light' : ''}`}>
      <Link
        to="/"
        onlyActiveOnIndex
      >
        Dan<i>Q</i>
      </Link>
    </div>
  );
};

export default Logo;
