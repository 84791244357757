import config from '../../../../config';
import { getConst, getDefaultInitState } from '../../../helpers/functions';
import { PROFILE_UNIVERSITY_NEWS_UNIT } from '../../../helpers/const';

const [ INITIAL_STATE_LOAD, INITIAL_STATE_SUCCESS, INITIAL_STATE_FAIL ] = getConst(PROFILE_UNIVERSITY_NEWS_UNIT);

export default function profile_university_news_unit(state = getDefaultInitState(), action = {}) {
  switch (action.type) {
    case INITIAL_STATE_LOAD:
      return {
        ...state,
        loading: true
      };
    case INITIAL_STATE_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.result
      };
    case INITIAL_STATE_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error
      };

    default:
      return state;
  }
}

export function isLoaded(globalState) {
  return globalState.profile_university_news_unit && globalState.profile_university_news_unit.loaded;
}

export function load(id) {
  return {
    types: [ INITIAL_STATE_LOAD, INITIAL_STATE_SUCCESS, INITIAL_STATE_FAIL ],
    promise: (client) => client.get(`${config.apiHost}/universities/news/edit/${id}`)
  };
}

export function saveNews(params, id) {
  const route = id ? `update/${id}` : 'store';
  return {
    types: [ INITIAL_STATE_LOAD, INITIAL_STATE_SUCCESS, INITIAL_STATE_FAIL ],
    promise: (client) => client.post(`${config.apiHost}/universities/news/${route}`, params)
  };
}
