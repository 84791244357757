import React, {Component} from 'react';
import Helmet from 'react-helmet';
import Container from "../../components/Common/Container";
import Center from "../../components/Common/Center";
import Margin from "../../components/Common/Margin";

export default class NotFound extends Component {
  render() {

    return (
      <main>
        <Helmet/>
        <Container>
          <Margin margin={50}/>
          <Center>
            <h1 style={{ fontSize: '36px' }}>404</h1>
          </Center>
          <Margin margin={50}/>
          <Center>
            <p>Похоже вы допустили опечатку, набирая адрес, или воспользовались неисправной ссылкой.</p>
          </Center>
        </Container>
      </main>
    );
  }
}
