import React from 'react';
import Slider from './Slider';
import Banner from './Banner';
import './TopBlock.scss';

const TopBlock = () => {
  return (
    <div className="main-page-top-block">
      <Slider/>
      <Banner/>
    </div>
  );
};

export default TopBlock;
