import { show_notify } from '../modules/notify/notify';
import { showLoading, hideLoading } from '../modules/loading/loading';

export default function clientMiddleware(client) {
  return ({
    dispatch, getState
  }) => next => async action => {
    if (typeof action === 'function') {
      return action(dispatch, getState);
    }

    const {
      promise, types, ...rest
    } = action;

    if (!promise) {
      return next(action);
    }

    dispatch(showLoading());

    const [ REQUEST, SUCCESS, FAILURE ] = types;

    next({
      ...rest,
      type: REQUEST
    });

    const actionPromise = promise(client);

    await actionPromise.then(
      (result) => {
        if ((result.alert && result.alert.message) && result.status) {
          dispatch(show_notify(result.alert.message, result.status));
        }

        next({
          ...rest,
          result,
          type: SUCCESS
        });

        dispatch(hideLoading());
      },
      (error) => {
        const { data } = error;

        if (parseInt(error.status, 10) === 403) {
          window.location = '/ru/forbidden';
        }

        if (data && data.alert && data.alert.message) {
          dispatch(show_notify(data.alert.message, 'error'));
        }

        next({
          ...rest,
          error,
          type: FAILURE
        });
        dispatch(hideLoading());
      }
    ).catch((error) => {
      console.error('MIDDLEWARE ERROR:', error);
      next({
        ...rest,
        error,
        type: FAILURE
      });
      dispatch(hideLoading());
    });

    return actionPromise;
  };
}
