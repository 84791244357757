import React from 'react';

const Margin = ({ margin }) => {
  const m = margin ? margin / 2 : 10 / 2;
  return (
    <div
      style={{
        padding: `${m}px 0`,
        display: 'block'
      }}
    />
  );
};


export default Margin;
