import React, { useState } from 'react';
import './Search.scss';
import Button from '../Common/Button';
import Margin from '../Common/Margin';
import Inputs from './Inputs';

const Main = (
  {
    location,
    router,
  }
) => {
  const [ active, setActive ] = useState('specialty');
  const s = { color: '#ffa42d' };
  const placeholder = active === 'specialty' ? 'Введите специальность' : 'Введите учебное заведение';
  const [ name, setName ] = useState('');

  const handleChangeUrl = async () => {
    if (name) {
      const query = `?${
        new URLSearchParams({
          ...location.query,
          name,
        }).toString()
      }`;

      router.push(`/ru/${active}${query}`);
    }
  };

  return (
    <div className="main-page-search">
      <div className="main-page-search-header">
        <span
          style={active === 'specialty' ? s : {}}
          onClick={() => setActive('specialty')}
        >
          Специальность
        </span>
        <span
          style={active === 'university' ? s : {}}
          onClick={() => setActive('university')}
        >
          Учебные заведения
        </span>
      </div>
      <Margin margin={20}/>
      <div className="main-page-search-main">
        <Inputs
          placeholder={placeholder}
          name={name}
          setName={setName}
          handleChangeUrl={handleChangeUrl}
          clearInput={() => setName('')}
        />
        <div className="main-page-search-button">
          <Button
            style="orange"
            height={50}
            changed={handleChangeUrl}
          >
            Найти
          </Button>
        </div>
      </div>
      <Margin margin={20}/>
      <div className="main-page-search-footer">
        <span>Последние запросы: маркетолог, финансист, веб-дизайнер</span>
      </div>
    </div>
  );
};

export default Main;
