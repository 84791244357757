import React from 'react';
import Title from './Title';
import './EducationEvents.scss';
import Margin from '../Common/Margin';
import Image from '../Common/Image';
import Button from '../Common/Button';

const EducationEvents = () => {
  return (
    <div className="main-page-education-events">
      <div>
        <Title>События учебных заведений</Title>
      </div>
      <Margin margin={50}/>
      <div className="main-page-education-events-body">
        <div style={{ width: '520px' }}>
          <Image src="/images/main/Слой 23.png"/>
        </div>
        <div className="main-page-education-events-body-content">
          <h3>КБТУ - Обладатель независимой награды «Лидер науки - Web of Science Award»</h3>
          <Margin margin={20}/>
          <p>25 ноября прошла церемония награждения независимой ежегодной премии Лидер науки - Web of Science Awards, которая проводится компанией Clarivate Analytics под эгидой Министерства образования и науки РК.</p>
          <Margin margin={34}/>
          <div style={{ width: '140px' }}>
            <Button style="brand">Подробнее</Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EducationEvents;
