import React from 'react';
import PlayerWrapper from '../Danq/PlayerWrapper';

const VideoBlock = () => {
  return (
    <div
      style={{
        borderRadius: '6px'
      }}
    >
      <PlayerWrapper url="https://media.w3.org/2010/05/sintel/trailer_hd.mp4"/>
    </div>
  );
};

export default VideoBlock;
