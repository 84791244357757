import React, { useState, useEffect } from 'react';
import Title from './Title';
import './CatalogOfSpecialties.scss';
import Specialty from '../Specialty/Item';
import Margin from '../Common/Margin';
import Button from '../Common/Button';
import List from '../Common/List';
import apiClient from '../../helpers/apiClient';
import config from '../../config';

const client = apiClient(null, 'ru', null);

const CatalogOfSpecialties = (
  {
    pushState
  }
) => {
  const [ info, setInfo ] = useState([]);

  useEffect(() => {
    client.get(`${config.apiHost}/main-specialities`).then(
      res => setInfo(res.data)
    );
  }, []);

  return (
    <div className="main-page-catalog-of-specialties">
      <div className="main-page-catalog-of-specialties-header">
        <Title>Каталог специальностей</Title>
        <span>Все категории </span>
      </div>
      <Margin margin={50}/>
      <List>
        {
          info.map((item, index) => (
            <Specialty
              key={index}
              item={item}
              index={index}
            />
          ))
        }
      </List>
      <Margin margin={25}/>
      <div className="main-page-catalog-of-specialties-btn">
        <Button
          style="brand"
          changed={() => pushState('/ru/specialty')}
        >
          Показать все специальности
        </Button>
      </div>
    </div>
  );
};

export default CatalogOfSpecialties;
