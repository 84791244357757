import config from '../../../config';
import { getConst } from '../../helpers/functions';
import { UPLOAD_IMAGE } from '../../helpers/const';

const [ INITIAL_STATE_LOAD, INITIAL_STATE_SUCCESS, INITIAL_STATE_FAIL ] = getConst(UPLOAD_IMAGE);

export function upload(params, header) {
  return {
    types: [ INITIAL_STATE_LOAD, INITIAL_STATE_SUCCESS, INITIAL_STATE_FAIL ],
    promise: (client) => client.post(`${config.apiHost}/images/store`, params, header)
  };
}

export function destroy(id, params, header) {
  return {
    types: [ INITIAL_STATE_LOAD, INITIAL_STATE_SUCCESS, INITIAL_STATE_FAIL ],
    promise: (client) => client.post(`${config.apiHost}/images/delete/${id}`, params, header)
  };
}
