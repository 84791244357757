function getConst(name) {
  return [ `${name}_STATE_LOAD`, `${name}_STATE_SUCCESS`, `${name}_STATE_FAIL` ];
}

function getDefaultInitState() {
  return {
    loaded: false
  };
}

export {
  getConst,
  getDefaultInitState,
};
