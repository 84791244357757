import config from '../../../../config';
import { getConst } from '../../../helpers/functions';
import { PROFILE_ENROLLEE_ANKETA_IS_ALLOWED } from '../../../helpers/const';

// eslint-disable-next-line max-len
const [ INITIAL_STATE_LOAD, INITIAL_STATE_SUCCESS, INITIAL_STATE_FAIL ] = getConst(PROFILE_ENROLLEE_ANKETA_IS_ALLOWED);

export default function profile_enrollee_anketa_is_allowed(state = {
  loaded: false,
  result: false
}, action = {}) {
  switch (action.type) {
    case INITIAL_STATE_LOAD:
      return {
        ...state,
        loading: true
      };
    case INITIAL_STATE_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.result
      };
    case INITIAL_STATE_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error
      };

    default:
      return state;
  }
}

export function isLoaded(globalState) {
  return globalState.profile_enrollee_anketa_is_allowed && globalState.profile_enrollee_anketa_is_allowed.loaded;
}

export function load() {
  return {
    types: [ INITIAL_STATE_LOAD, INITIAL_STATE_SUCCESS, INITIAL_STATE_FAIL ],
    promise: (client) => client.get(`${config.apiHost}/anketa/is-allowed`)
  };
}
