import { createStore as _createStore, applyMiddleware, compose } from 'redux';
import { routerMiddleware } from 'react-router-redux';
import createMiddleware from './middleware/clientMiddleware';
import thunk from 'redux-thunk';

export default function createStore(history, client, data) {
  const middleware = [
    createMiddleware(client),
    routerMiddleware(history),
    thunk
  ];
  const finalCreateStore = applyMiddleware(...middleware)(_createStore);
  const reducer = require('./reducer').default;

  return finalCreateStore(reducer, data);

  // if (__DEVELOPMENT__ && module.hot) {
  //     module.hot.accept('./reducer', () => {
  //         store.replaceReducer(require('./reducer'));
  //     });
  // }

  // return store;
}
