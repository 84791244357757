import React from 'react';
import './Navs.scss';
import { Link } from 'react-router';

const Navs = ({ isScrolled }) => {
  const fontSize = isScrolled ? '14px' : '16px';
  return (
    <nav
      className="header-navs"
      style={{ fontSize }}
    >
      <Link
        to="/ru/specialty"
        onlyActiveOnIndex
      >
        Специальности
      </Link>
      <Link
        to="/ru/university"
        onlyActiveOnIndex
      >
        Учебные заведения
      </Link>
    </nav>
  );
};

export default Navs;
