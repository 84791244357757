import React from 'react';
import Image from '../Common/Image';

const Banner = () => {
  return (
    <div>
      <Image
        src="/images/main/banner.svg"
      />
    </div>
  );
};

export default Banner;
