import config from '../../../config';
import { getConst, getDefaultInitState } from '../../helpers/functions';
import { TEST_START } from '../../helpers/const';

const [ INITIAL_STATE_LOAD, INITIAL_STATE_SUCCESS, INITIAL_STATE_FAIL ] = getConst(TEST_START);

export default function test_start(state = getDefaultInitState(), action = {}) {
  switch (action.type) {
    case INITIAL_STATE_LOAD:
      return {
        ...state,
        loading: true
      };
    case INITIAL_STATE_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.result
      };
    case INITIAL_STATE_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error
      };

    default:
      return state;
  }
}

export function isLoaded(globalState) {
  return globalState.test_start && globalState.test_start.loaded;
}

export function start(params) {
  return {
    types: [ INITIAL_STATE_LOAD, INITIAL_STATE_SUCCESS, INITIAL_STATE_FAIL ],
    promise: (client) => client.post(`${config.apiHost}/test/start`, params)
  };
}
