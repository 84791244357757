import React from 'react';

const Svg = ({
  size, ...props
}) => (
  <svg
    height={size}
    width={size}
    viewBox="0 0 20 20"
    aria-hidden="true"
    focusable="false"
    style={{
      display: 'inline-block',
      fill: 'currentColor',
      lineHeight: 1,
      stroke: 'currentColor',
      strokeWidth: 0,
    }}
    {...props}
  />
);

const ClearIcon = (props) => (
  <Svg
    size={20}
    {...props}
  >
    {/* eslint-disable-next-line max-len */}
    <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z" />
  </Svg>
);


const Inputs = (
  {
    placeholder,
    height,
    name,
    setName,
    clearInput,
    handleChangeUrl,
  }
) => {
  const s = height ? { height } : { height: '50px' };
  return (
    <>
      <div
        className="main-page-search-inputs"
        style={s}
      >
        <div>
          <span>
            <img
              src="/icons/Прямоугольник 1 копия 2.svg"
              alt="danq"
            />
          </span>
          <input
            placeholder={placeholder}
            value={name}
            onKeyDown={
              (event) => {
                if (event.key === 'Enter') {
                  handleChangeUrl();
                }
              }
            }
            onChange={
              event => {
                event.preventDefault();
                event.stopPropagation();
                setName(event.target.value);
              }
            }
          />
          <span
            onClick={
              async (event) => {
                event.preventDefault();
                event.stopPropagation();
                await clearInput();
              }
            }
            className="main-page-search-input-clear"
          >
            <ClearIcon/>
          </span>
        </div>
      </div>
    </>
  );
};

export default Inputs;
