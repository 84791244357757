import cookie from 'cookie';
import config from '../../../config';
import { AUTH, LOGOUT } from '../../helpers/const';
import { getConst, getDefaultInitState } from '../../helpers/functions';

const [ INITIAL_STATE_LOAD, INITIAL_STATE_SUCCESS, INITIAL_STATE_FAIL ] = getConst(AUTH);
const [ LOGOUT_LOAD, LOGOUT_SUCCESS, LOGOUT_FAIL ] = getConst(LOGOUT);

export default function auth(state = getDefaultInitState(), action = {}) {
  switch (action.type) {
    case INITIAL_STATE_LOAD:
      return {
        ...state,
        loading: true
      };
    case INITIAL_STATE_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        user: action.result.user
      };
    case INITIAL_STATE_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        user: null
      };
    case LOGOUT_LOAD:
      return {
        ...state,
        loggingOut: true
      };
    case LOGOUT_SUCCESS:
      return {
        ...state,
        loggingOut: false,
        user: null
      };
    case LOGOUT_FAIL:
      return {
        ...state,
        loggingOut: false,
        logoutError: action.error
      };

    default:
      return state;
  }
}

export function load() {
  return {
    types: [ INITIAL_STATE_LOAD, INITIAL_STATE_SUCCESS, INITIAL_STATE_FAIL ],
    promise: (client) => client.get(`${config.apiHost}/check-login`)
  };
}

export function isLoaded(globalState) {
  return globalState.auth && globalState.auth.loaded;
}

export function loginPost(params) {
  return {
    types: [ INITIAL_STATE_LOAD, INITIAL_STATE_SUCCESS, INITIAL_STATE_FAIL ],
    promise: (client) => client.post(`${config.apiHost}/login`, params)
  };
}

export function registerPost(params) {
  return {
    types: [ INITIAL_STATE_LOAD, INITIAL_STATE_SUCCESS, INITIAL_STATE_FAIL ],
    promise: (client) => client.post(`${config.apiHost}/register`, params)
  };
}

export function logout() {
  const token = cookie.parse(document.cookie).token || '';
  const headers = { Authorization: `Bearer ${token}` };
  return {
    types: [ LOGOUT_LOAD, LOGOUT_SUCCESS, LOGOUT_FAIL ],
    promise: (client) => {
      return client.get(
        `${config.apiHost}/logout`,
        { headers }
      );
    }
  };
}
