import React from 'react';
import Container from '../Common/Container';
import TopBlock from './TopBlock';
import Search from '../Search/Main';
import HowIsItWork from './HowIsItWork';
import CatalogOfSpecialties from './CatalogOfSpecialties';
import EducationEvents from './EducationEvents';
import EducationSlider from './EducationSlider';
import EnterUniversity from './EnterUniversity';
import Margin from '../Common/Margin';
import CustomHr from '../Common/CustomHr';
import Info from './Info';
import Universities from './Universities';

const Index = (
  {
    pushState,
    location,
    router,
    profile_enrollee_entrance_is_allowed,
  }
) => {
  const margin = <Margin margin={50}/>;
  return (
    <>
      <Margin margin={50}/>
      <Container>
        <TopBlock/>
        {margin}
        <Search
          location={location}
          router={router}
        />
        {margin}
        <HowIsItWork/>
        {margin}
      </Container>
      <CustomHr/>
      <Container>
        {margin}
        <Info/>
        {margin}
      </Container>
      <CustomHr/>
      <Container>
        {margin}
        <CatalogOfSpecialties
          pushState={pushState}
        />
        {margin}
      </Container>
      <CustomHr/>
      <Container>
        {margin}
        <EducationEvents/>
        {margin}
      </Container>
      <CustomHr/>
      <Container>
        {margin}
        <Universities/>
        {margin}
      </Container>
      <CustomHr/>
      <Container>
        {margin}
        <EducationSlider/>
        {margin}
      </Container>
      <EnterUniversity
        pushState={pushState}
        profile_enrollee_entrance_is_allowed={profile_enrollee_entrance_is_allowed}
      />
    </>
  );
};

export default Index;
