import React from 'react';
import './Button.scss';

const Button = (
  {
    type,
    children,
    style,
    disabled,
    changed,
    height
  }
) => {
  return (
    <button
      type={type}
      className={`Button ${style}`}
      disabled={ disabled }
      style={{ height: `${height}px` }}
      onClick={
        (event) => {
          changed && changed(event);
        }
      }
    >
      { children }
    </button>
  );
};

Button.defaultProps = {
  type: 'button'
};

export default Button;
