import React from 'react';
import { IndexRoute, Route } from 'react-router';
import cookie from 'cookie';
import { Main, NotFound } from './pages';
import App from './App';
import { isLoaded as isAuthLoaded, load as loadAuth, } from './redux/modules/auth/auth';
// eslint-disable-next-line max-len
import { isLoaded as isAllowedEnrolleeEntranceLoaded, load as loadAllowedEnrolleeEntrance, } from './redux/modules/profile/enrollee/entrance_is_allowed';
import { setIdentity } from './helpers/urlIdentity';

export default (store) => {
  function loadRoute(cb) {
    return (module) => cb(null, module.default);
  }

  const locale = cookie.parse(document.cookie).locale || 'ru';

  const requireLogin = (nextState, replace, cb) => {
    function checkAuth() {
      const {
        auth: { user }, routing
      } = store.getState();
      if (!user) {
        setIdentity(routing.locationBeforeTransitions.pathname);
        replace(`/${locale}/login`);
      }
      cb();
    }

    if (!isAuthLoaded(store.getState())) {
      store.dispatch(loadAuth()).then(checkAuth);
    } else {
      checkAuth();
    }
  };

  const isAllowedEnrolleeEntrance = (nextState, replace, cb) => {
    function check() {
      const {
        profile_enrollee_entrance_is_allowed: {
          data: { result }
        }
      } = store.getState();

      if (!result) {
        replace(`/${locale}/profile/enrollee/questionnaire/1`);
      }
      cb();
    }

    if (!isAllowedEnrolleeEntranceLoaded(store.getState())) {
      store.dispatch(loadAllowedEnrolleeEntrance()).then(check);
    } else {
      check();
    }
  };

  return (
    <Route
      path="/(:locale(kk|ru))"
      component={App}
    >
      <IndexRoute component={Main}/>
      <Route
        path="registration"
        getComponent={(loc, cb) => import('./pages/Auth/Registration').then(loadRoute(cb))}
      />
      <Route
        path="login"
        getComponent={(loc, cb) => import('./pages/Auth/Login').then(loadRoute(cb))}
      />
      <Route
        path="reset-password"
        getComponent={(loc, cb) => import('./pages/Auth/ResetPassword').then(loadRoute(cb))}
      />
      <Route
        path="specialty"
        getComponent={(loc, cb) => import('./pages/Specialty/Index').then(loadRoute(cb))}
      />
      <Route
        path="specialty/:id"
        getComponent={(loc, cb) => import('./pages/Specialty/Show').then(loadRoute(cb))}
      />
      <Route
        path="university"
        getComponent={(loc, cb) => import('./pages/University/Index').then(loadRoute(cb))}
      />
      <Route
        path="university/:slug"
        getComponent={(loc, cb) => import('./pages/University/Show').then(loadRoute(cb))}
      />
      <Route
        path="educations/news/:slug"
        getComponent={(loc, cb) => import('./pages/News/Show').then(loadRoute(cb))}
      />
      <Route onEnter={isAllowedEnrolleeEntrance}>
        <Route
          path="profile/enrollee/entrance-order"
          getComponent={(loc, cb) => import('./pages/Profile/Enrollee/EntranceOrder').then(loadRoute(cb))}
        />
      </Route>
      <Route onEnter={requireLogin}>
        <Route
          path="profile/enrollee/settings"
          getComponent={(loc, cb) => import('./pages/Profile/Enrollee/Settings').then(loadRoute(cb))}
        />
        <Route
          path="profile/enrollee/status"
          getComponent={(loc, cb) => import('./pages/Profile/Enrollee/Status').then(loadRoute(cb))}
        />
        <Route
          path="profile/enrollee/news"
          getComponent={(loc, cb) => import('./pages/Profile/Enrollee/News').then(loadRoute(cb))}
        />
        <Route
          path="profile/enrollee/info"
          getComponent={(loc, cb) => import('./pages/Profile/Enrollee/Info').then(loadRoute(cb))}
        />
        <Route
          path="profile/enrollee/account"
          getComponent={(loc, cb) => import('./pages/Profile/Enrollee/Account').then(loadRoute(cb))}
        />
        <Route
          path="profile/enrollee/feedback"
          getComponent={(loc, cb) => import('./pages/Profile/Enrollee/Feedback').then(loadRoute(cb))}
        />
        {/* <Route */}
        {/*  path="profile/enrollee/account/replenishment/:status" */}
        {/*  getComponent={(loc, cb) => import('./pages/Profile/Enrollee/AccountReplenishment').then(loadRoute(cb))} */}
        {/* /> */}
        <Route
          path="profile/enrollee/questionnaire/:step"
          getComponent={(loc, cb) => import('./pages/Profile/Enrollee/EntranceQuestionnaire').then(loadRoute(cb))}
        />
        <Route
          path="profile/enrollee/entrance-list"
          getComponent={(loc, cb) => import('./pages/Profile/Enrollee/EntranceList').then(loadRoute(cb))}
        />
        <Route
          path="profile/enrollee/entrance-show/:id/:step"
          getComponent={(loc, cb) => import('./pages/Profile/Enrollee/EntranceShow').then(loadRoute(cb))}
        />
        <Route
          path="profile/enrollee/test/:id/:question"
          getComponent={(loc, cb) => import('./pages/Profile/Enrollee/Test/Test').then(loadRoute(cb))}
        />
        <Route
          path="profile/enrollee/test-result/:id"
          getComponent={(loc, cb) => import('./pages/Profile/Enrollee/Test/Result').then(loadRoute(cb))}
        />
        <Route
          path="profile/university/history"
          getComponent={(loc, cb) => import('./pages/Profile/University/History').then(loadRoute(cb))}
        />
        <Route
          path="profile/university/info"
          getComponent={(loc, cb) => import('./pages/Profile/University/Info').then(loadRoute(cb))}
        />
        <Route
          path="profile/university/employers"
          getComponent={(loc, cb) => import('./pages/Profile/University/Employers').then(loadRoute(cb))}
        />
        <Route
          path="profile/university/entrance"
          getComponent={(loc, cb) => import('./pages/Profile/University/Entrance').then(loadRoute(cb))}
        />
        <Route
          path="profile/university/entrance/:id/:step"
          getComponent={(loc, cb) => import('./pages/Profile/University/EntranceStep').then(loadRoute(cb))}
        />
        <Route
          path="profile/university/faculties"
          getComponent={(loc, cb) => import('./pages/Profile/University/Faculties').then(loadRoute(cb))}
        />
        <Route
          path="profile/university/education-programs"
          getComponent={(loc, cb) => import('./pages/Profile/University/EducationPrograms').then(loadRoute(cb))}
        />
        <Route
          path="profile/university/education-programs/create"
          getComponent={(loc, cb) => import('./pages/Profile/University/EducationProgramsCreate').then(loadRoute(cb))}
        />
        <Route
          path="profile/university/education-programs/edit/:id"
          getComponent={(loc, cb) => import('./pages/Profile/University/EducationProgramsEdit').then(loadRoute(cb))}
        />
        <Route
          path="profile/university/specialities"
          getComponent={(loc, cb) => import('./pages/Profile/University/Specialities').then(loadRoute(cb))}
        />
        <Route
          path="profile/university/news"
          getComponent={(loc, cb) => import('./pages/Profile/University/News').then(loadRoute(cb))}
        />
        <Route
          path="profile/university/news/create"
          getComponent={(loc, cb) => import('./pages/Profile/University/NewsCreate').then(loadRoute(cb))}
        />
        <Route
          path="profile/university/news/edit/:id"
          getComponent={(loc, cb) => import('./pages/Profile/University/NewsEdit').then(loadRoute(cb))}
        />
        <Route
          path="profile/university/blocks"
          getComponent={(loc, cb) => import('./pages/Profile/University/Blocks').then(loadRoute(cb))}
        />
      </Route>
      <Route
        path="forbidden"
        status={403}
        getComponent={(loc, cb) => import('./pages/Forbidden/Forbidden').then(loadRoute(cb))}
      />
      <Route
        path="*"
        component={NotFound}
        status={404}
      />
    </Route>
  );
};
