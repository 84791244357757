import React from 'react';
import Image from '../Common/Image';
import './EducationSlider.scss';

const l = [
  'Layer копия 7.png',
  'logo-bl копия.png',
  'Слой 9 копия 2.png',
  'Слой 598 копия.png',
];

const EducationSlider = () => {
  return (
    <div className="main-page-education-slider">
      <div className="main-page-education-slider-arrow">
        <span>
          <img
            src="/images/main/arrow_2 копия 7.svg"
            alt="danq"
          />
        </span>
      </div>
      <div className="main-page-education-slider-list">
        {
          l.map((item, index) => (
            <Image
              src={`/images/main/${item}`}
              key={index}
            />
          ))
        }
      </div>
      <div className="main-page-education-slider-arrow">
        <span>
          <img
            src="/images/main/arrow_2 копия 6.svg"
            alt="danq"
          />
        </span>
      </div>
    </div>
  );
};

export default EducationSlider;
